html,
body {
    background-color: white;
    font-family: 'Work Sans', sans-serif;
}

.changing-scroll::-webkit-scrollbar {
    display: none
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: none !important;
    box-shadow: none !important;
}

.offcanvas-backdrop {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}